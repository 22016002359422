import React from "react";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import History from "./components/Histroy";
import Team from "./components/Team";
import Testimonials from "./components/Testimonials";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import LogoScroller from "./components/LogoScroller";
import Owners from "./components/Owners";



import "../styles/Home.scss";

function __MAIN__() {
  /* Irgendwann fixen wenn ich den nerv dazu hab */
  window.addEventListener('scroll', () => {
    const reveals = document.querySelectorAll('.reveal');

    for (let i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let revealTop = reveals[i].getBoundingClientRect().top;
      let revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  });


  return (
    <div>
      <Navbar />
      <div id="landing"><Landing /></div>
      <div id="services"><Services /></div>
      <div id="owners"><Owners /></div>
      <div id="team"><Team /></div>
      <div id="history"><History /></div>
      <div id="testimonials"><Testimonials /></div>
      <div id="contact"><Contact /></div>
      <div id="logos"><LogoScroller /></div>
      <div id="footer"><Footer /></div>


    </div>
  );
}

export default __MAIN__;