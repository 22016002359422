import React, { useState } from "react";
import { Container, Carousel } from "react-bootstrap";
import history from "../../data/history";
import "../../styles/History.scss";

function History() {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="py-5 text-center w-75 mx-auto">
      <h1
        className="mb-4 text-error"
        style={{
          fontSize: "5rem",
        }}
      >
        Unsere Historie
      </h1>

      {/* History Carousel */}
      <Carousel
        activeIndex={currentIndex}
        onSelect={(selectedIndex) => setCurrentIndex(selectedIndex)}
        interval={3500} // Auto-slide every 5 seconds
        className="shadow-lg position-relative rounded"
        style={{
          height: window.innerWidth <= 768 ? "750px" : "650px",
        }}
      >
        {history.map((item, index) => (
          <Carousel.Item key={item.id} className="p-4">
            <div className="d-flex flex-column align-items-center">
              <h2 className="text-error fs-1">{item.date}</h2>
              <h3 className="fw-bold">{item.title}</h3>
              <img
                src={item.img}
                alt={item.title}
                className="img-fluid mb-3"
                style={{
                  width: "450px",
                  height: "350px",
                  objectFit:
                    item.img === "./img/history/schulshop.jpg" || item.img === "./img/history/recycle.png" 
                    || item.img === "./img/history/platzhirsch.png" 
                      ? "contain"
                      : "cover",
                }}
              />
              <p className="lead fs-4">{item.content}</p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default History;
