import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScrewdriverWrench,
  faComputer,
  faNetworkWired,
  faMicrochip,
  faLaptopCode,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import services from "../../data/services";
import "../../styles/Services.scss";

// Mapping each service ID to a FontAwesome icon
const serviceIcons = {
  "edv-services": faComputer,
  repair: faScrewdriverWrench,
  "network-solutions": faNetworkWired,
  hardware: faMicrochip,
  software: faLaptopCode,
  "care-packages": faShieldAlt,
};

function Service({ id, title, description }) {
  return (
    <Col md={6} lg={4} className="mb-5 d-flex justify-content-center">
      <div className="position-relative text-center">
        {/* Floating FontAwesome Icon */}
        <div
          className="rounded-circle bg-white p-3 shadow-lg position-absolute start-50 translate-middle"
          style={{ top: "-12px", zIndex: 1 }}
        >
          <FontAwesomeIcon
            icon={serviceIcons[id]}
            size="2x"
            className="text-error"
          />
        </div>

        {/* Service Card */}
        <Card
          className="shadow-lg border-0 rounded-4 p-4 text-center"
          style={{
            maxWidth: "350px",
            height: window.innerWidth < 1200 ? "270px" : "225px",
          }}
        >
          <Card.Title className="text-error fw-bold fs-3">{title}</Card.Title>
          <Card.Text className="text-muted ">{description}</Card.Text>
        </Card>
      </div>
    </Col>
  );
}

function Services() {
return (
  <Container className="py-5 text-center">
    <h1
      className="mb-4  text-error"
      style={{
        fontSize: "5rem",
      }}
    >
      Unsere Services
    </h1>
    <img
      src="/img/services/fuhrpark.png"
      alt="Fuhrpark"
      className="img-fluid"
      style={{
        width: "100%",
        height: "30rem",
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "0.75rem",
        marginBottom: "-50px",
      }}
    />
    <Row className="justify-content-center">
      {Object.values(services).map((service, index) => (
        <Service key={index} {...service} />
      ))}
    </Row>
  </Container>
);
}

export default Services;
