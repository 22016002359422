import prices from "./support_prices"

const support = [
  {
    id: 1,
    title: "Netzwerk & Druckertechniker",
    price_h: parseFloat(
      prices.find((price) => price.id === "network/printer").price_h.toFixed(1)
    ),
    price_h_mwst: parseFloat(
      (
        prices.find((price) => price.id === "network/printer").price_h * 1.2
      ).toFixed(1)
    ),
    price_15: parseFloat(
      (
        prices.find((price) => price.id === "network/printer").price_h / 4
      ).toFixed(1)
    ),
    price_15_mwst: parseFloat(
      (
        (prices.find((price) => price.id === "network/printer").price_h / 4) *
        1.2
      ).toFixed(1)
    ),
    workers: [
      {
        id: 1,
        name: "Lukas Arnold",
        img: "./img/team/lukas.png",
      },
      {
        id: 2,
        name: "Lukas Gobl",
        img: "./img/team/lukasG.jpg",
      },
      {
        id: 3,
        name: "Slobodan Petrovic",
        img: "./img/team/slobodan.jpg",
      },
      {
        id: 4,
        name: "Sebastian Weickl",
        img: "./img/team/sebastian.jpg",
      },
      {
        id: 5,
        name: "Simon Hirscher",
        img: "./img/team/simon.jpg",
      },
    ],
  },
  {
    id: 2,
    title: "PC TechnikerInnen",
    price_h: parseFloat(
      prices.find((price) => price.id === "repair").price_h.toFixed(1)
    ),
    price_h_mwst: parseFloat(
      (prices.find((price) => price.id === "repair").price_h * 1.2).toFixed(1)
    ),
    price_15: parseFloat(
      (prices.find((price) => price.id === "repair").price_h / 4).toFixed(1)
    ),
    price_15_mwst: parseFloat(
      (
        (prices.find((price) => price.id === "repair").price_h / 4) *
        1.2
      ).toFixed(1)
    ),
    workers: [
      {
        id: 1,
        name: "Thomas Gschwandtner",
        img: "./img/team/thomas.jpg",
      },
      {
        id: 2,
        name: "Natalia Mukieieva",
        img: "./img/team/natalia.jpg",
      },
      {
        id: 3,
        name: "Martin Lasar",
        img: "./img/team/martin.jpg",
      },
      {
        id: 4,
        name: "Andreas Renoth",
        img: "./img/team/andreas.jpg",
      },
    ],
  },
  {
    id: 3,
    title: "ERP Softwaretechniker",
    price_h: parseFloat(
      prices.find((price) => price.id === "software").price_h.toFixed(1)
    ),
    price_h_mwst: parseFloat(
      (prices.find((price) => price.id === "software").price_h * 1.2).toFixed(1)
    ),
    price_15: parseFloat(
      (prices.find((price) => price.id === "software").price_h / 4).toFixed(1)
    ),
    price_15_mwst: parseFloat(
      (
        (prices.find((price) => price.id === "software").price_h / 4) *
        1.2
      ).toFixed(1)
    ),
    workers: [
      {
        id: 1,
        name: "Olcay Gündüz",
        img: "./img/team/olcay.jpg",
      },
      {
        id: 2,
        name: "Stefan Innerlohinger",
        img: "./img/team/stefan.jpg",
      },
    ],
  },
  {
    id: 4,
    title: "Junge Techniker",
    price_h: parseFloat(
      prices.find((price) => price.id === "young-technician").price_h.toFixed(1)
    ),
    price_h_mwst: parseFloat(
      (
        prices.find((price) => price.id === "young-technician").price_h * 1.2
      ).toFixed(1)
    ),
    price_15: parseFloat(
      (
        prices.find((price) => price.id === "young-technician").price_h / 4
      ).toFixed(1)
    ),
    price_15_mwst: parseFloat(
      (
        (prices.find((price) => price.id === "young-technician").price_h / 4) *
        1.2
      ).toFixed(1)
    ),
    workers: [
      {
        id: 1,
        name: "Fabian Fürlinger",
        img: "./img/team/fabian.png",
      },
    ],
  },
  {
    id: "General",
    min_price_15: parseFloat(
      (Math.min(...prices.map((price) => price.price_h)) / 4).toFixed(1)
    ),
    min_price_15_mwst: parseFloat(
      ((Math.min(...prices.map((price) => price.price_h)) / 4) * 1.2).toFixed(1)
    ),
    max_price_15: parseFloat(
      (Math.max(...prices.map((price) => price.price_h)) / 4).toFixed(1)
    ),
    max_price_15_mwst: parseFloat(
      ((Math.max(...prices.map((price) => price.price_h)) / 4) * 1.2).toFixed(1)
    ),
  },
];

export default support;