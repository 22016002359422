import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const Owners = () => {
return (
    <div
        className="position-relative text-white py-5 d-flex justify-content-center p-5"
        style={{
            background: "linear-gradient(to right, #e30513, #fa7a80)",
        }}
    >
        <Container>
            <Row className="align-items-center justify-content-center">
                <Col md={6} className="text-center">
                    <img
                        src="./img/misc/owners.png"
                        alt="Owners"
                        className="img-fluid rounded"
                    />
                </Col>
                <Col md={6}>
                    <div className="text-md-start text-center px-md-4">
                        <h2 className="fw-bold fs-1">Die Eigentümer</h2>
                        <p className="fs-4">
                            Die Gründung 1978 durch Ing. Karl H. Lorentschitsch setzte den
                            Grundstein für das Computer Center Lorentschitsch. Heute steht
                            mit Leona Lorentschitsch, BA, MBA und Ing. Gerald Fürlinger die
                            zweite Generation in den Startlöchern, um gemeinsam mit einem
                            starken Team diese Erfolgsgeschichte weiterzuführen.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);
};

export default Owners;
