const histroy = [
  {
    id: "founding",
    title: "Unternehmensgründung",
    date: "1978",
    content: "Gründung des Unternehmens durch Ing. Karl H. Lorentschitsch",
    img: "./img/history/p2001.jpg",
  },
  {
    id: "new-headquarters",
    title: "Neue Firmenzentrale",
    date: "1981",
    content:
      "Eröffnung der neuen Firmenzentrale in Salzburg Gnigl mit eigenem Shop",

    img: "./img/history/new-branch.png",
  },
  {
    id: "new-branch",
    title: "Neue Filiale",
    date: "1983",
    content:
      "Eröffnung einer Filiale in der Wolf Dietrich Straße für Privatkunden",
    img: "./img/history/new-headquaters.jpeg",
  },
  {
    id: "company-newspaper",
    title: "Firmenzeitung",
    date: "1987",
    content: 'Erste Firmenzeitung "Lorentschitsch Report" erscheint',
    img: "./img/history/company-newspaper.jpeg",
  },
  {
    id: "gmbh",
    title: "Lorentschitsch GmbH",
    date: "1994",
    content: "Gründung der Computer Center Lorentschitsch GmbH",
    img: "./img/history/platzhirsch.png",
  },
  {
    id: "pupils",
    title: "Start der Lehrlingsausbildung",
    date: "1995",
    content:
      "1995 als erster EDV Lehrling beginnt Slobodan Petrovic seine Ausbildung im Betrieb - heute ist er Technischer Leiter",
    img: "./img/history/pupils.jpg",
  },
  {
    id: "web",
    title: "Webauftritt",
    date: "1999",
    content: "Erster professioneller Webauftritt unter www.lorentschitsch.at",
    img: "./img/history/news.png",
  },
  {
    id: "schulshop",
    title: "Schulshop",
    date: "2003",
    content:
      "www.schulshop.at startet als Salzburger Online-Shop speziell für Schulen und LehrerInnen",
    img: "./img/history/schulshop.jpg",
  },
  {
    id: "new-building",
    title: "Neuer Standort",
    date: "2004",
    content: "Umzug in ein komplett renoviertes, familieneigenes Gebäude",
    img: "./img/history/new-building.jpeg",
  },
  {
    id: "lehrbetrieb",
    title: "Bester Lehrbetrieb",
    date: "2006",
    content:
      "2006 erhält Lorentschitsch die Auszeichnung als bester Lehrbetrieb in Salzburg",
    img: "./img/history/lehrbetrieb.jpeg",
  },
  {
    id: "new-showroom",
    title: "Neuer Schauraum",
    date: "2013",
    content:
      "Komplettumbau und Modernisierung unseres Kundenbereichs inklusive Eröffnung unserer Sofortreparaturwerkstatt",
    img: "./img/history/new-showroom.jpeg",
  },
  {
    id: "green",
    title: "Go Green",
    date: "2021",
    content:
      "Errichtung einer 35kwp Photovoltaikanlage mit 3 Elektrotankstellen und Umstellung der Firmenflotte von 14 Fahrzeugen auf Elektroantrieb",
    img: "./img/history/recycle.png",
  },
];

export default histroy; 