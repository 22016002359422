import React, { useState } from "react";
import { Container, Row, Col, Nav, Card } from "react-bootstrap";
import workers from "../../data/workers";

const WorkerCard = ({ id, name, position, phone, email, img, msg }) => {

    const imgSrc = new Image();
    imgSrc.src = img;

    return (
      <Col md={6} lg={4} className="mb-4 d-flex justify-content-center">
        <Card
          className={`shadow border-0 text-center d-flex flex-column align-items-center p-3 ${msg ? "bg-secondary text-white" : ""}`}
          style={{ width: "350px", height: "375px", position: "relative", justifyContent: "space-around" }}
        >
          {/* Circular Profile Image */}
          <div
            className="worker-img rounded-circle bg-secondary"
            id={id}
            style={{ width: "150px", height: "150px", backgroundImage: `url(${imgSrc.src})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          ></div>

          <Card.Body className="d-flex flex-column align-items-center" style={{ flex: 1 }}>
            <Card.Title className="fw-bold">{name}</Card.Title>
            <Card.Text className="text-muted text-center">
              {position} <br />
              {phone && (
                <>
                   {phone} <br />
                </>
              )}
              {email && <> {email}</>}
            </Card.Text>
          </Card.Body>

          {msg && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <span className="text-white fw-bold fs-3">{msg}</span>
            </div>
          )}
        </Card>
      </Col>
    );
};

function Team() {
  const tabs = [
    { key: "leader", label: "Operative Leitung" },
    { key: "accounting", label: "Buchhaltung" },
    { key: "sales", label: "Verkauf" },
    { key: "repair", label: "Technik & Reparatur" },
    { key: "network", label: "Netzwerktechnik" },
    { key: "software", label: "Software" },
    { key: "buying", label: "Einkauf" },
    { key: "logistics", label: "Logistik" },
  ];

  const [selectedTab, setSelectedTab] = useState("leader");

  return (
    <Container className="py-5 text-center">
      <h1
        className="mb-4  text-error"
        style={{
          fontSize: "5rem",
        }}
      >
        Unser Team
      </h1>

      <Nav variant="pills" className="justify-content-center mb-4">
        {tabs.map((tab) => (
          <Nav.Item key={tab.key}>
            <Nav.Link
              eventKey={tab.key}
              active={selectedTab === tab.key}
              onClick={() => setSelectedTab(tab.key)}
              className={`fw-bold fs-5 ${
                selectedTab === tab.key ? "bg-error" : "text-secondary"
              }`}
            >
              {tab.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Row className="justify-content-center">
        {workers[selectedTab]?.map((worker) => (
          <WorkerCard key={worker.id} {...worker} />
        ))}
      </Row>
    </Container>
  );
}

export default Team;
