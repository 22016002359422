const services = {
    "edv-services": {
        id: "edv-services",
        title: "EDV-Dienstleistungen",
        description: "Unsere Mitarbeiter bieten regelmäßige Pflege, Updates, Fehlerbehebungen und Aufrüstungen für IT-Systeme an, sowie schnelle Wiederherstellungszeiten und Leihgeräte im Schadensfall.",
    },
    "repair": {
        id: "repair",
        title: "Sofort-Reparatur",
        description: "In unserem 2023 eröffneten Reparatur-Café können Sie bei dringenden Reparaturen eine Tasse Tee oder Kaffee genießen und den Spezialisten live zusehen. Bitte vereinbaren Sie vorher einen Termin.",
    },
    "network-solutions": {
        id: "network-solutions",
        title: "Netzwerklösungen",
        description: "Seit 1978 betreuen wir Unternehmen unterschiedlicher Größe mit flexiblen, sicheren und effizienten Netzwerklösungen, unterstützt durch kompetente und kostenlose Beratungsgespräche.",
    },
    "hardware": {
        id: "hardware",
        title: "Hardware",
        description: "Wir bieten Lösungen für jede Art von IT-Hardware, ob Gaming-PCs, CAD-Workstations oder mobile Arbeitsgeräte, und beraten Sie gerne persönlich oder online.",
    },
    "software": {
        id: "software",
        title: "Software",
        description: "Wir bieten professionelle Warenwirtschaftslösungen von Mesonic und Etron. Auch in Lizenzfragen bei den führenden Softwareanbietern stehen wir mit Rat und Tat zur Seite.",
    },
    "care-packages": {
        id: "care-packages",
        title: "Betreuungspakete",
        description: "Wir bieten IT-Check-ups und kontinuierliches Monitoring für Ihre Systeme, um Ihre IT aktuell und sicher zu halten, und definieren gemeinsam mit Ihnen den passenden Servicerahmen.s",
    },
}

export default services;
