import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const contacts = [
  {
    icon: faMapMarkerAlt,
    title: "Kommen Sie vorbei!",
    text: "Rott-Au 20, 5020 Salzburg",
    link: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      "Rott-Au 20, 5020 Salzburg"
    )}`,
  },
  {
    icon: faPhone,
    title: "Rufen Sie uns an!",
    text: "+43 / (0)662 / 660505",
  },
  {
    icon: faEnvelope,
    title: "Schreiben Sie uns!",
    text: "office@lorentschitsch.at",
    link: "mailto:office@lorentschitsch.at",
  },
  {
    icon: faClock,
    title: "Unsere Öffnungszeiten",
    text: "Montag bis Freitag: 08:00 - 17:00 Uhr",
  },
];

function ContactCard({ icon, title, text, link }) {
    return (
        <Col md={6} lg={3} className="d-flex justify-content-center mb-4">
            <Card className="shadow border-0 text-center p-3" style={{ width: "100%", maxWidth: "350px" }}>
                <FontAwesomeIcon icon={icon} size="3x" className="text-error mb-3" />
                <Card.Body>
                    <Card.Title className="fs-5">{title}</Card.Title>
                    {link ? (
                        <Card.Text>
                            <a href={link} style={{ textDecoration: "none" }}>
                                {text}
                            </a>
                        </Card.Text>
                    ) : (
                        <Card.Text>{text}</Card.Text>
                    )}
                </Card.Body>
            </Card>
        </Col>
    );
}

function Contact() {
  return (
    <Container className="py-5 text-center">
      <h1
        className="mb-4  text-error"
        style={{
          fontSize: "5rem",
        }}
      >
        Kontakt
      </h1>
      <Row className="justify-content-center">
        {contacts.map((contact, index) => (
          <ContactCard key={index} {...contact} />
        ))}
      </Row>
    </Container>
  );
}

export default Contact;
