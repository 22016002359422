import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import CustomNavbar from "./components/Navbar";
import support from "../data/support";

function SupportGroup({ supportItem }) {
  return (
    <Card className="mb-4 shadow-lg border-0 rounded-4 overflow-hidden">
      <Card.Body className="p-4">
        <Card.Title className="fs-3 fw-bold text-error">
          {supportItem.title}
        </Card.Title>
        <hr className="mb-3" />

        <div className="mb-3 fs-5">
          <p className="mb-1 text-muted">
            Preis pro Stunde:{" "}
            <strong className="text-dark">{supportItem.price_h}€</strong>
          </p>
          <p className="mb-1 text-muted">
            Preis pro Stunde inkl. Mwst:{" "}
            <strong className="text-dark">{supportItem.price_h_mwst}€</strong>
          </p>
          <p className="mb-1 text-muted">
            Preis pro 15 Minuten:{" "}
            <strong className="text-dark">{supportItem.price_15}€</strong>
          </p>
          <p className="mb-1 text-muted">
            Preis pro 15 Minuten inkl. Mwst:{" "}
            <strong className="text-dark">{supportItem.price_15_mwst}€</strong>
          </p>
        </div>

        {supportItem.workers && (
          <Row className="mt-4" style={{ height: "350px", overflowY: "auto" }}>
            {supportItem.workers.map((worker) => (
              <Col
                xs={6}
                md={6}
                lg={6}
                xl={3}
                key={worker.id}
                className="d-flex justify-content-center mb-2"
              >
                <Card className="border-0 text-center worker-card shadow-sm w-100"
                style={{
                    width: "160px",
                    height: "160px"
                }}>
                  <Card.Img
                    variant="top"
                    src={worker.img}
                    alt={worker.name}
                    className="rounded-circle mx-auto mt-2"
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                  <Card.Body className="p-2">
                    <Card.Text className="fw-bold">{worker.name}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}

function Support() {
return (
  <>
    <CustomNavbar sameSite={false} />

    <Container className="py-5">
      <h1
        className="mb-4 text-center  text-error"
        style={{
          fontSize: "3rem",

        }}
      > 
        Fernwartung
      </h1>
      <Row className="justify-content-center">
        {support.slice(0, 4).map((supportItem) => (
          <Col md={6} lg={6} key={supportItem.id}>
            <SupportGroup supportItem={supportItem} />
          </Col>
        ))}
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <a href="https://get.teamviewer.com/cclsbg" className="btn btn-success me-3 fs-1"
          style={{width: "200px"}}>
            Annehmen
          </a>
          <a href="/" className="btn btn-danger fs-1"
          style={{width: "200px"}}>
            Ablehnen
          </a>
        </Col>
      </Row>
    </Container>
  </>
);
}

export default Support;
