import { useRef } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import "../../styles/Navbar.scss";

function CustomNavbar({ sameSite = true }) {
    const navRef = useRef();
    const navigate = useNavigate()

    const showNavbar = () => {
        navRef.current.classList.toggle("show");
    };

    const handleNavLinkClick = (href) => {
        if (!sameSite) {
            navigate("/")
            setTimeout(() => {
                window.location.href = href;
            }, 100);
        } else {
            window.location.href = href;
        }
    };

    return (
      <Navbar
        ref={navRef}
        expand="lg"
        className="shadow-sm w-100 bg-primary-bg"
        style={{ position: "sticky", top: 0, zIndex: 1000 }}
      >
        <Container className="d-flex align-items-center justify-content-between">
          {/* Brand Logo (Left Aligned) */}
          <Navbar.Brand href="#" onClick={() => handleNavLinkClick("#")}>
            <img width={225} src="./img/misc/logo.png" alt="Brand Logo" />
          </Navbar.Brand>

          {/* Navbar Toggle for Mobile View */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={showNavbar}>
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>

          {/* Navigation Links (Centered) */}
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="fs-3 gap-3 text-center">
              <Nav.Link onClick={() => handleNavLinkClick("#services")}>
                Leistungen
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("#team")}>
                Unser Team
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("#history")}>
                Historie
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("#testimonials")}>
                Kundenmeinungen
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("#contact")}>
                Kontakt
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  handleNavLinkClick("https://shop.lorentschitsch.at/")
                }
              >
                Web-Shop  
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default CustomNavbar;
