const LOGO_COUNT = 14

const logos = []

for (let i = 1; i <= LOGO_COUNT; i++) {
    logos.push({
        id: i,
        img: `./img/logos/${i}.${i === 3 || i === 10 || i === 13 ? 'jpg' : 'png'}`,
        alt: `Logo ${i}`,
    });
}


export default logos;