import React from "react";
import { Container, Accordion } from "react-bootstrap";
import CustomNavbar from "./components/Navbar";

function Datenschutz() {
    const currentYear = new Date().getFullYear();
  return (
    <> 
        <CustomNavbar  sameSite={false}/>
    <Container className="py-5">
      {/* Page Header */}
      <h1
        className="mb-4 text-center  text-error"
        style={{
          fontSize: "3rem",
        }}
      >
        Datenschutz
      </h1>

      {/* Datenschutz Details */}
      <Accordion defaultActiveKey="0">
        {/* Verantwortlich */}
        <Accordion.Item eventKey="0">
          <Accordion.Header id="verantwortlich">
            Verantwortlich
          </Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>Computer Center Lorentschitsch GmbH</strong>
            </p>
            <p>Rott Au 20, 5020 Salzburg, Österreich</p>
            <p>
              Email:{" "}
              <a href="mailto:office@lorentschitsch.at">
                office@lorentschitsch.at
              </a>
            </p>
            <p>Telefon: +43/662/660505</p>
          </Accordion.Body>
        </Accordion.Item>

        {/* Datenschutzbeauftragter Details */}
        <Accordion.Item eventKey="1">
          <Accordion.Header id="datenschutzbeauftragter">
            Datenschutzbeauftragter
          </Accordion.Header>
          <Accordion.Body>
            <p>Unseren Datenschutzbeauftragten erreichen Sie unter:</p>
            <p>
              <strong>z.H. Ing. Karl Lorentschitsch</strong>
            </p>
            <p>Rott Au 20, 5020 Salzburg</p>
            <p>Telefon: +43/662/660505</p>
            <p>
              Email:{" "}
              <a href="mailto:office@lorentschitsch.at">
                office@lorentschitsch.at
              </a>
            </p>
          </Accordion.Body>
        </Accordion.Item>

        {/* Hosting Provider */}
        <Accordion.Item eventKey="2">
          <Accordion.Header id="hosting">Hosting Provider</Accordion.Header>
          <Accordion.Body>
            <p>
              Wir hosten unsere Website bei unserem Auftragsverarbeiter{" "}
              <a href="https://edv-datenservice.at/">EDV & Datenservice KG</a>, Moosfeldstraße 1b, 5101
              Bergheim, Österreich.
            </p>
            <p>
              Zum Zweck der Bereitstellung und der Auslieferung der Website
              werden Verbindungsdaten verarbeitet. Zum bloßen Zweck der
              Auslieferung und Bereitstellung der Website werden die Daten über
              den Aufruf hinaus nicht gespeichert.
            </p>
            <p>
              <strong>Rechtsgrundlage:</strong> Berechtigtes Interesse
              (unbedingte technische Notwendigkeit zur Bereitstellung und
              Auslieferung des von ihnen durch ihren Aufruf ausdrücklich
              gewünschten Dienstes „Website“) gemäß Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <p>
              Zum Betrieb der Website werden die Verbindungsdaten und weitere
              personenbezogenen Daten zusätzlich im Rahmen diverser anderer
              Funktionen bzw. Dienste verarbeitet. Darüber wird im Rahmen dieser
              Datenschutzerklärung bei den einzelnen Funktionen bzw. Diensten im
              Detail informiert.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        {/* Server Log Files */}
        <Accordion.Item eventKey="3">
          <Accordion.Header id="server-log">Server Log Files</Accordion.Header>
          <Accordion.Body>
            <p>
              Zum Zweck der Überwachung der technischen Funktion und zur
              Erhöhung der Betriebssicherheit unseres Webhosts werden
              Verbindungsdaten verarbeitet. Die Dauer der Verarbeitung ist auf
              14 Tage beschränkt.
            </p>
            <p>
              <strong>Rechtsgrundlage:</strong> Das berechtigte Interesse
              (unbedingte technische Notwendigkeit eines Server Log Files als
              grundlegende Datenbasis zur Fehleranalyse und für
              Sicherheitsmaßnahmen im Rahmen des durch Ihren Aufruf ausdrücklich
              gewünschten Dienstes „Website“) gemäß Art. 6 Abs. 1 lit. f DSGVO.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        {/* Kontaktformular */}
        <Accordion.Item eventKey="4">
          <Accordion.Header id="contact-form">Kontaktformular</Accordion.Header>
          <Accordion.Body>
            <p>
              Auf unserer Website besteht die Möglichkeit, durch ein
              Kontaktformular direkt mit uns in Kontakt zu treten. Nach Absenden
              des Kontaktformulars erfolgt eine Verarbeitung der von Ihnen
              eingegebenen personenbezogenen Daten durch den Verantwortlichen
              zum Zweck der Bearbeitung Ihrer Anfrage auf Grundlage der von
              Ihnen durch das Absenden des Formulars erteilten Einwilligung gem.
              Art. 6 Abs. 1 lit. a DSGVO bis auf Widerruf.
            </p>
            <p>
              Es besteht keine gesetzliche oder vertragliche Verpflichtung zur
              Bereitstellung der personenbezogenen Daten. Die
              Nichtbereitstellung hat lediglich zur Folge, dass Sie Ihr Anliegen
              nicht übermitteln und wir dieses nicht bearbeiten können.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        {/* Sicherheitsdienste */}
        <Accordion.Item eventKey="5">
          <Accordion.Header id="security">
            Sicherheitsdienste (Captcha)
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Auf dieser Website nutzen wir zur Vermeidung von nicht
              menschlichen und automatisierten Eingaben das Angebot von
              Sicherheitsdienstleistern wie Captcha-Diensten.
            </p>
            <p>
              <strong>Google reCAPTCHA</strong>
            </p>
            <p>
              Im Fall der Erteilung Ihrer Einwilligung verarbeiten wir mit dem
              Dienst{" "}
              <a href="https://cloud.google.com/security/products/recaptcha">
                Google reCaptcha
              </a>
              , Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland als gemeinsame Verantwortliche Ihre personenbezogenen Daten
              zum Zweck der Vermeidung nicht menschlicher und automatisierter
              Eingaben. Dabei ermöglichen wir dem Dienst das Setzen von Cookies,
              die Erhebung von Verbindungsdaten und Daten ihres Webbrowsers.
              Zudem ermöglichen wir dem Dienst die Berechnung einer User-ID zur
              eindeutigen Identifizierung des Users im Rahmen des von Google
              betriebenen Werbenetzwerkes. Daten werden auf Ihrem Gerät für die
              Dauer von bis zu zwei Jahren gespeichert.
            </p>
            <p>
              Die Rechtsgrundlage für die Datenverarbeitung ist Ihre
              Einwilligung gemäß Art. 6 Abs 1 lit. a DSGVO. Die Nichterteilung
              der Einwilligung hat zur Folge, dass die Nutzung von reCaptcha und
              damit verbundenen Formularen nicht möglich ist.
            </p>
            <p>
              Eine bereits erteilte Einwilligung können Sie widerrufen, indem
              Sie die Datenschutzeinstellungen ändern.
            </p>
            <p>
              Der Google Konzern übermittelt Ihre personenbezogenen Daten in die
              USA. Die Rechtsgrundlage für die Datenübermittlung in die USA ist
              Ihre Einwilligung gemäß Art. 49 Abs 1 lit a iVm Art. 6 Abs 1 lit a
              DSGVO in Verbindung mit §25 TTDSG. Sie wurden bereits vor
              Erteilung Ihrer Einwilligung informiert, dass die USA über kein
              den Standards der EU entsprechendes Datenschutzniveau verfügt.
              Insbesondere können US Geheimdienste auf Ihre Daten zugreifen,
              ohne dass Sie darüber informiert werden und ohne dass Sie dagegen
              rechtlich vorgehen können. Der Europäische Gerichtshof hat aus
              diesem Grund in einem Urteil den früheren Angemessenheitsbeschluss
              (Privacy Shield) für ungültig erklärt.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        {/* Web Schriften */}
        <Accordion.Item eventKey="6">
          <Accordion.Header id="web-fonts">Web Schriften</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>Font Awesome</strong>
            </p>
            <p>
              Wir verarbeiten mit unserem Auftragsverarbeiter{" "}
              <a href="https://fontawesome.com/">Fontawesome</a>, Fonticons,
              Inc., 6 Porter Road, Apartment 3R, Cambridge, MA 02140, USA,
              Verbindungsdaten und Browserdaten zum Zweck der Bereitstellung der
              vom Webbrowser zur Anzeige der Website benötigten Schriftarten.
              Diese Daten werden nur für die zur Auswahl und Übermittlung der
              Schriftarten benötigte Dauer verarbeitet.
            </p>
            <p>
              Die Rechtsgrundlage für die Datenverarbeitung ist das berechtigte
              Interesse (unbedingte technische Notwendigkeit zur Bereitstellung
              und Auslieferung des von Ihnen durch Ihren Aufruf ausdrücklich
              gewünschten Dienstes „Website“) gemäß Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <p>
              Soweit durch Fontawesome eine weitergehende eigenständige
              Verarbeitung der Daten erfolgt, ist Fontawesome dafür alleinige
              Verantwortliche. Details finden Sie in der{" "}
              <a href="https://fontawesome.com/privacy">Datenschutzerklärung</a>{" "}
              von Fontawesome.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header id="rights">Ihre Rechte</Accordion.Header>
          <Accordion.Body>
            <h5>Widerspruchsrecht</h5>
            <p>
              Sofern die Verarbeitung Ihrer personenbezogenen Daten auf
              Grundlage des berechtigten Interesses erfolgt, haben Sie das Recht
              gegen diese Verarbeitung zu widersprechen.
            </p>
            <p>
              Sofern keine zwingenden schutzwürdigen Gründe für die Verarbeitung
              unsererseits vorliegen, wird die Verarbeitung Ihrer Daten auf
              Basis dieser Rechtsgrundlage eingestellt.
            </p>
            <p>
              Zudem haben Sie das Recht, der Verarbeitung Ihrer
              personenbezogenen Daten zum Zweck der Direktwerbung zu
              widersprechen. Im Fall des Widerspruchs werden Ihre
              personenbezogenen Daten nicht mehr zum Zweck der Direktwerbung
              verarbeitet.
            </p>
            <p>
              Die Rechtmäßigkeit der bis zum Widerspruch verarbeiteten Daten
              wird durch den Widerspruch nicht berührt.
            </p>
            <h5>Widerrufsrecht</h5>
            <p>
              Sie haben das Recht eine bereits erteilte Einwilligung jederzeit
              zu widerrufen, indem Sie die Datenschutzeinstellungen ändern.
            </p>
            <p>
              Im Fall der Einwilligung in den Erhalt elektronischer Werbung kann
              der Widerruf Ihrer Einwilligung durch Klick auf den Abmeldelink
              erfolgen. In diesem Fall wird eine Verarbeitung, sofern keine
              andere Rechtsgrundlage besteht, eingestellt.
            </p>
            <p>
              Die Rechtmäßigkeit der bis zum Widerruf verarbeiteten Daten wird
              durch den Widerruf nicht berührt.
            </p>
            <h5>Betroffenenrechte</h5>
            <p>
              Sie haben zudem das Recht auf Auskunft, Berichtigung, Löschung und
              Einschränkung der Verarbeitung der personenbezogenen Daten.
            </p>
            <p>
              Soweit die Rechtsgrundlage der Verarbeitung Ihrer
              personenbezogenen Daten in Ihrer Einwilligung oder in einem mit
              Ihnen abgeschlossenen Vertrag besteht, haben Sie überdies das
              Recht auf Datenübertragbarkeit.
            </p>
            <p>
              Weiters haben Sie das Recht auf Beschwerde bei der
              Aufsichtsbehörde. Nähere Informationen zu den Aufsichtsbehörden in
              der Europäischen Union finden Sie{" "}
              <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
                hier
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Footer */}
      <div className="text-center mt-5">
        <p className="text-muted">
          © {currentYear} Computer Center Lorentschitsch GmbH - Alle Rechte vorbehalten
        </p>
      </div>
    </Container>
    </>
  );
}

export default Datenschutz;
