const prices = [
    {
        id: "network/printer",
        price_h: 123, 
    },
    {
        id: "repair",
        price_h: 108,
    },
    {
        id: "software",
        price_h: 129,
    },
    {
        id: "young-technician",
        price_h: 77,
    },
]

export default prices;