import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import __MAIN__ from "./pages/__MAIN__";
import Error404 from "./pages/Error404";
import Support from "./pages/Support";
import Datenschutz from "./pages/Datenschutz";


import "./styles/customstyles.scss"
import "bootstrap/dist/css/bootstrap.min.css";


const router = createBrowserRouter([
  {
    path: "/",
    element: <__MAIN__/>,
  },
  {
    path: "*",
    element: <Error404/>,
  },
  {
    path: "/fernwartung",
    element: <Support/>,
  },
  {
    path: "/datenschutz",
    element: <Datenschutz/>,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);